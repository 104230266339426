@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

/* Blog styling! */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}
body {
  font-family: 'Montserrat', sans-serif;
}
button,
input {
  font-family: 'Montserrat', sans-serif;
}

#id {
  display: none;
}

@media only screen and (max-width: 600px) {
  #id {
    display: default;
  }
}

/* Devlog */

#devlogpost {
  background-image: "url(../../devlog_bg.png)"
}

/* BlogPost */

.blog-bg {
  max-width: 1200px;
  background-color: black;
  border-style: solid;
  border-color:#1d1d1d;
  border-width: 5px;
  margin: 0 auto;
  min-height: 100vh;
}

.dtww-bg {
  max-width: 1200px;
  background-color: black;
  border-style: solid;
  border-color:#d1d1d1;
  border-width: 5px;
  margin: 0 auto;
  padding: 1em;
  min-height: 100vh;
}

.blog-footer {
    min-height: 3.5em;
    max-height: 10em;
}

.blog-footer.copynotice {
  margin: auto;
  text-align: center;
  color: white;
}

.blog-wrap {
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
}



.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  padding-top: 2rem;
  margin-left: 2rem;
  display: block;
}
.blog-wrap header {
  text-align: center;
  font-size: 3rem;
}
.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}
.blog-wrap img {
  width: 100%;
}
.blog-subCategory {
  display: flex;
  justify-content: center;
}
.blog-subCategory > div {
  margin: 1rem;
}
.blog-content p{
  margin-top: 1.5rem;
  padding: 1rem;
}
.blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5, .blog-content h6 {
  margin-top: 1.5rem;
}



.blog-content img {
  margin: 10px 0;
}
/* Search Bar */
.searchBar-wrap, .create-form input {
  background-color: #f0f0f0;
  width: fit-content;
  margin: 0rem 0rem 0rem 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  position: fixed;
  top: 1rem;
}
.searchBar-wrap form, .create-form input {
  display: flex;
  align-items: center;
}
.searchBar-wrap input {
  background-color: #f0f0f0;
  outline: none;
  border: none;
}
.searchBar-wrap span {
  padding-right: 0.5rem;
  cursor: pointer;
}
.searchBar-wrap button, .btn {
  outline: none;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  background-color: #0f52ba;
  color: #fff;
}

.btn{
  z-index: 5;
}
/* Header */
.home-header {
  text-align: center;
}
.home-header h2 {
  color: #0080ff;
  font-size: 2rem;
}
.home-header h1 {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 1rem;
}
.home-header h1 span {
  color: #b0c4de;
}
.home-header p {
  color: #a9a9a9;
  font-weight: 500;
  max-width: 800px;
  margin: auto;
  padding-bottom: 2em;
}

/* Blog List Stubs */
.blogList-stubs-wrap {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  margin: auto;
  margin-top: 1rem;
}

@media (min-width: 640px) {
  .blogList-stubs-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Blog List Full */
.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Blog Shorthand */

.blogShorthand-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #0000002d;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  max-width: 100%;
}



.blogShorthand-desc {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 0.6rem;
  padding-bottom: 0.2rem;
  font-size: 0.8rem;
  color: #000000;
  text-align: center;
  align-self: center;
}
.blogShorthand-link {
  text-decoration: none;
  color: black;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.2rem;
}

.blogShorthand-author {

  align-items: center;
  color: rgb(162, 253, 246);
  font-size: 0.8rem;
  margin: auto;
}

/* Blog Item */
.blogItem-wrap {
  display: flex;
  flex-direction: column;
}
.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
}
.blogItem-desc {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}
.blogItem-desc::before {
  position: absolute;
  content: '...';
  bottom: 0;
  right: 0;
}
.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}
.blogItem-link {
  text-decoration: none;
  color: inherit;
}
.blogItem-author {
  display: flex;
  align-items: center;
}
.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}
.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}
/* Empty List */
.emptyList-wrap {
  display: flex;
  justify-content: center;
  margin: 10rem 0rem 0rem 0rem;
}
.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}
/* Chip */
.chip {
  font-size: 0.7rem;
  background: linear-gradient(to right, #6190e8, #a7bfe8);
  color: #fff;
  padding: 0.3rem 0.5rem;
  margin-left: 0.5rem;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  text-transform: capitalize;
  align-self: center;
}

/* Create content form */
.create-form{
  position: absolute;
  height: 90vh;
  top: 0;
  z-index: 3;
  backdrop-filter: blur(5px);
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formcont{
  background: #fff;
  width: 80%;
  display: flex;
  padding: 10px 10px;
  flex-direction: column;
  height: 80%;
  justify-content: center;
}
.formcont textarea{
  border: 1px solid #ccc;
  outline: none;
  resize: none;
  padding: 10px;
  font-size: 0.7rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: 100%;
  height: 12em;
}
.formcont span{
  display: flex;
}
.file{
  margin: 7px!important;
  padding: 0 !important;
}

/* START OF SPECIAL BORDER STYLING */

.countach {
	font-family: countach,sans-serif;
	font-weight: 400;
	font-style: normal;
}

.tilda-petite {
	font-family: tilda-petite,sans-serif;
	font-weight: 400;
	font-style: normal;
}

.montserrat {
	font-family: montserrat, sans-serif;
	font-weight: 300;
	font-style: normal;
}

.skintone-text {
	color: var(--skintone-color);
}

.dark-text {
	color: var(--dark-color);
}

.white-text {
	color: #fff;
}

a:hover {
	color: #c2c2c2;
}


.dark-background {
	max-width: 1200px;
  background-color: black;
  margin: 0 auto;
  padding: 1em;
  min-height: auto;

  border: 6px solid #ffffff;
   	height: 99%;
    width: 98%;
    padding: 6px;
    margin: 0 auto;
}

@media (min-width: 625px) {

  .dark-background {
    border: 2px solid #ffffff;
   	height: 99%;
    width: 98%;
    padding: 6px;
    margin: 0 auto;
  }
}

.map-spec {
  width: 100vw;
  background-color: black;
  margin: 0 auto;
  padding: 1em;
  min-height: auto;

  border: 6px solid #ffffff;
   	height: 100%;
    width: 100%;
    padding: 6px;
    margin: 0 auto;
}

@media (min-width: 625px) {
	.map-spec {
    min-width: 80vw;
    max-width: 90vw;

    border: 2px solid #ffffff;
      height: 99%;
      width: 98%;
      padding: 6px;
      margin: 0 auto;
  }
}

@media (min-width: 625px) {
	.mid-border-map {
    border: 6px solid #ffffff;
    width: 100%;
    padding: 6px;
    margin: auto;
  }

  .mid-border {
    min-height: 100vh;
    border: 6px solid #ffffff;
    width: 100%;
    padding: 6px;
    margin: auto;
}
}


.inner-border {
	position: relative;
	border: 2px solid #ffffff;
    height: auto;
    width: 100%;
    margin: auto;
}


/* Decorations */
.corner-decoration {
	position: absolute;
    width: 3em;
    margin: -3px;
}

@media (min-width: 768px) {
	.corner-decoration {
		width: 3.5em;
	  	margin: -4px;
	}
}

@media (min-width: 992px) {
	.corner-decoration {
		width: 4em;
	  	margin: -5px;
	}
}

@media (min-width: 1200px) {
	.corner-decoration {
		width: 5em;
	  	margin: -6px;
	}
}

.corner-decoration.corner-left-top {
	left: 0;
	top: 0;
}

.corner-decoration.corner-right-top {
	top: 0;
	right: 0;
	 -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.corner-decoration.corner-right-bottom {
	right: 0;
	bottom: 0;
	 -webkit-transform: scale(-1);
  	transform: scale(-1);
}

.corner-decoration.corner-left-bottom {
	left: 0;
	bottom: 0;
	-webkit-transform: scaleY(-1);
  	transform: scaleY(-1);
}


.vertical-decoration {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	width: 6em;
}


@media (min-width: 768px) {
	.vertical-decoration {
		width: 13em;
	}
}


@media (min-width: 992px) {
	.vertical-decoration {
		width: 16em;
	}
}

@media (min-width: 1200px) {
	.vertical-decoration {
		width: 20em;
	}
}

.vertical-decoration.top {
	top: 0;

}

.vertical-decoration.bottom {
	bottom: 0;
	-webkit-transform: scaleY(-1);
  	transform: scaleY(-1);
}

#map {
  max-height: 100vh;
  min-height: auto;
  background-color: transparent;

  flex: 1;
}

.mapcontent {
  display: flex;
  flex-direction: column;
  height: auto;
}

@media (min-width: 625px) {
	.mapcontent {
    display:flex;
    flex-direction: row;
    height: 95vh;
  }
}

.map-info{
  width: 92vw;
}

@media (min-width: 625px) {
	.map-info{
    width: 40vw;
  }
}


.map-info-content{
  font-family: 'Times New Roman';
  color: #dfdfdf;
  font-size: 1.1rem;
}

.map-return{
	font-family: 'IM Fell DW Pica';
	font-size: 1.5rem;
	color: #c4635c;
}



.settlement {
  vertical-align: bottom;
  bottom: 0%;
  max-width: 100%;
  max-height: 60%;
}

@media (min-width: 625px) {
  .settlement {
    top: 0;
    vertical-align: middle;
    top: 10%;
    left: 49%;
    max-width: 60%;
    max-height: 85%;
    bottom: auto;
    
  }
}

.settlement-centered {
  vertical-align: bottom;
  bottom: 0%;
  max-width: 100%;
  max-height: 60%;
}

@media (min-width: 625px) {
  .settlement-centered {
    top: 0;
    vertical-align: middle;
    top: 10%;
    left: 27%;
    max-width: 60%;
    max-height: 85%;
    bottom: auto;
    
  }
}

.mapBlocker {
  vertical-align: bottom;
  bottom: 0%;
  min-width: 100%;
  min-height: 100%;
}

@media (min-width: 625px) {
  .mapBlocker {
    top: 0;
    vertical-align: top;
    top: 0%;
    left: 0%;
    min-width: 100%;
    min-height: 100%;
    bottom: auto;
    
  }
}

.bio-container img{
  margin: auto;
  vertical-align: top;
}

/* DTWW WORLD SUBPAGE IMAGES */

#huge-header{
  padding: 2rem;
  width: 100%;
}

#realm-header{
  padding: 2rem;
}

#realm-title{
  width: 60%;
}

#what-header{
  padding: 2rem 2rem 0 2rem;
  width: 70%
}

#what-title{
  padding-bottom: 2rem;
}


#inhab-header{
  padding: 2rem;
}

@media (min-width: 625px) {
  #huge-header{
    padding-top: 4rem;
    width: 50%;
    padding-bottom: 4rem;
  }

  #what-header{
    padding-top: 4rem;
    width: 30% !important;
  }

  #what-title{
    width: 40%;
    padding-bottom: 2rem;
  }

  #inhab-header{
    padding-top: 4rem;
    width: 45%
  }

  #realm-header{
    padding-top: 4rem;
    width: 40%
  }
}


/* INHABITANTS PAGE  */

#species-box{
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}

#poli-grid-box{
  display: flex;
  flex-wrap: wrap;
  padding: 0rem 1rem;
}



#poligrid-link{
  flex-basis: 50%;
  font-family: "IM Fell DW Pica";
  font-size: 1.5rem;
  margin: auto;
  text-align: center;
  padding: 1.5rem 0;
  flex-grow: 0;
}

#subtitle-tiny {
  font-size: 1.0rem;
}

#politic-box{
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 625px) {
  #poli-grid-box{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    padding: 0rem 5rem;
  }

  #poligrid-link{
    flex-basis: 33.3333333%;
  }
}

@media (min-width: 1000px) {
  #poli-grid-box{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    padding: 0rem 5rem;
  }

  #poligrid-link{
    flex-basis: 25%;
  }
}


#species-box h2{
  color: gray;
  font-size: 3rem;
  padding-left: 0rem;
  font-family: "IM Fell DW Pica";
}

#politic-box h2{
  color: gray;
  font-size: 2.5rem;
  padding-left: 2rem;
  font-family: "IM Fell DW Pica";
}

#padding-scrunch{
  padding-bottom: 1.5rem !important;
}

.wwheader h1{
  font-size: 3rem;
  font-family: "IM Fell DW Pica";
}


#spec-header-box h1{
  color: gray;
  font-size: 3rem;
  font-family: "IM Fell DW Pica";
}

#spec-header-box h1, #spec-header-box p{
  padding-left: 2rem;
  padding-right: 1rem;
  text-align: left !important;
}

@media (min-width: 625px) {

  #spec-header-box h1, #spec-header-box p{
    padding-left: 4rem;
    padding-right: 3rem;
    text-align: left !important;
  }

}

#species-box p{
  text-align: left;
  padding-right: 3rem;
  
}

#species-text {
  flex-basis: 70%;
}

@media (min-width: 625px) {
  #majorpol-text{
    flex-basis: 80%;
  }
}

#portrait {
  width: 20%;
}


#banner {
  display: none;
}

@media (min-width: 625px) {
  #banner {
    width: 10%;
    display: block;
  }
}


#polwedge {
  flex-basis: 10%;
  display: none;
}

.force-vert-center {
  padding: 2rem;
}

@media (min-width: 625px) {
  .force-vert-center {
    vertical-align: center;
    align-self: center;
    padding: 4rem 0;
  }

  #polwedge {
    flex-basis: 10%;
    display: block;
  }
}

#descintro{
  color: rgb(194, 190, 190) !important;
  width: 83.333333%
}

#break{
  flex-basis: 100%;
  height: 0;
}

#wwpagelist{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin: auto;
  list-style-type: disc;
  text-align: left !important;
  color: rgb(136, 177, 132);
  width: 80%;
}

.bio-container p{
  color: white;
  text-align: center;
  
  margin: auto;
  padding-bottom: 2rem; 
  display: block;

  height: auto;
}

#text-center-83{
  width: 83.33333%;
}

.text-left-83 {
  width: 83.33333%;
  text-align: left !important;
}

.map-polygon {
  opacity: 0;
  transition: opacity .5s;
}

.map-polygon:hover {
  opacity:0;
  transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  -webkit-transition: opacity .2s ease-out;
  -o-transition: opacity .2s ease-out;
}

.polygon-hover
{
  opacity: 1;
}

h1.double {
  border-top: 8px double;
  border-bottom: none;
  width: 90%;
  margin: auto;
  padding-bottom: 3.5rem;
}

h1.default {
  border-top-width: 4px;
  width: 80%;
  opacity: 45%;
  margin: auto;
  padding-bottom: 3.5rem;
}

/*faux-tailwind for DangerouslySet inlines*/

.brand-text { 
  color: rgb(88, 181, 133) !important;
}

.red {
  color: rgb(207, 64, 64) !important;
}

.white {
  color: white !important;
}

.purple {
  color: rgb(156, 91, 156) !important;
}

.blue {
  color: rgb(33, 136, 150) !important;
}

.gold {
  color: rgb(204, 180, 72) !important;
}

.orange {
  color: rgb(204, 136, 81) !important;
}

.green {
  color: rgb(70, 173, 87) !important;
}

.grey{
  color: grey;
}

