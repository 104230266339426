.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.wwlogo{
  height: 10em;
  display: flex;
}

@media (min-width: 625px) {
  .wwlogo{
    height: 20em;
    display: flex;
  }
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#about{
  max-width: 1200px;
  margin: auto;
}

/* Navbar container */
.navbar {
  overflow: hidden;
  background-color: #33333300;
  font-family: Arial;
  max-width: 1200px;
  margin: auto;
}

.wwnavbar {
  margin: auto;
  max-width: 1200px;
  overflow: hidden;
  background-color: #33333300;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: 'IM Fell DW Pica';
}

.wwdnavbar {
  margin: auto;
  max-width: 1200px;
  overflow: hidden;
  background-color: #33333300;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: 'IM Fell DW Pica';
}

/* Links inside the navbar */
.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;

}

.wwdnavbar button {
  float: left;
  font-size: 24px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: 'IM Fell DW Pica';
}

.wwnavbar img {
  max-height: 2.5em;
  min-height: 1em;
  float: left;
  margin-bottom: auto;
  object-fit: cover;
}

.hpnavbar {
  min-height: 60px !important;
  display: flex;
  flex-wrap: wrap-reverse;
}



@media (max-width: 600px) {
	.logo-img{
		width: 100%;
    height: 50%;
	}

  .cartoon-sun{
    display: none !important;
  }
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
  flex-basis: 40%; 
}

.rootpic {
  padding: 0 !important;
  flex-basis: 40%;
}


/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

@media (min-width: 625px) {
	.dropdown {
    flex-basis: auto;
  }
  
  .rootpic {
    flex-basis: auto;
  }
}





	.picbar {
    height: 100%;
    width: 100%;
    display: block;
    margin: 0;
  }
  
  .picbar-img {
    height: 100%;
    display: block;
  }

  @media (min-width: 640px) {
    .picbar-img {

      width: 100%;

    }
  }



/* Add a red background color to navbar links on hover */
.hpnavbar .wwdnavbar a:hover, .dropdown:hover .dropbtn {
  background-color: rgb(47, 211, 121);
}

/* Add a red background color to navbar links on hover */
.wwnavbar a:hover {
  -webkit-filter: invert(50%);
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.cartoon-sun {
  position: absolute;
  width: auto;
  height: auto;
  top: 80px;
  right: 70px;
  animation: rotation 240s infinite linear;
}

@media (max-width: 600px) {
	.cartoon-sun{
		width: 15%;
	}
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

#back-button {
  max-width: 1200px;
  margin: auto;
}

.worldwell {
  cursor: url('/public/tiny_cursor.png'), auto;
}

.worldwell a:hover {
  cursor: url('/public/tiny_clickable_hand.png'), auto;
}


.leaflet-container{
  height: 70vh;
  max-width: 192vh;
  margin: auto;
  background: none !important;
  z-index: 0 !important;
  flex: 1;

}


@media (min-width: 625px) {
  .leaflet-container{
    height: 95vh;
  }
}


.leaflet-grab {
	cursor: url('/public/tiny_cursor.png'), auto !important;
	}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
	cursor: url('/public/tiny_cursor.png'), auto !important;
	}
.leaflet-popup-pane,
.leaflet-control {
	cursor: url('/public/tiny_cursor.png'), auto !important;
	}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
	cursor: url('/public/tiny_cursor.png'), auto !important;
}

.leaflet-interactive{
  transition: opacity 4s linear;
}

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted gray; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 320px;
    background-color: black;
    color: #fff;
    border: 2px solid white;
    padding: 10px 10px;
    text-align: center;
    border-radius: 6px;
    margin-bottom: 5px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  .tooltiptop {
    width: 120px;
    bottom: 100%;
    left: 20%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }

  .tooltipbottom {
    width: 120px;
    top: 100%;
    left: 20%;
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

